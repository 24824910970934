<template>
  <div class="grid-container">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12">
        <h1>
          Viewing Rooms
          <button @click="logOut" class="float-right button ml-2">LOG OUT</button>
          <router-link to="/add-room" class="float-right button">Add Room</router-link>
        </h1>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-4">
      <div class="cell small-12">
        <table>
          <thead>
            <tr>
              <th>Title</th>
              <th>Status</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(room, index) in viewingRooms" :key="index">
              <td>{{ room.title }}</td>
              <td>
                <select @change="updateStatus(index)" v-model="room.status">
                  <option value="1">In development</option>
                  <option value="2">Current</option>
                  <option value="3">Previous</option>
                  <option value="3">Archived</option>
                </select>
              </td>
              <td class="text-right">
                <router-link :to="`/edit-room/${room.id}`">
                  <button class="button small">Edit</button>
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios';

export default {
  name: 'Start',
  data() {
    return {
      viewingRooms: [],
    };
  },
  methods: {
    logOut() {
      this.$store.dispatch('unsetCredentials');
      this.$router.push('/');
    },
    getViewingRooms() {
      axios.get(`/viewingRooms/get.json?token=${this.$store.state.token}`)
        .then((response) => {
          this.viewingRooms = response.data;
        });
    },
    updateStatus(index) {
      const viewingRoom = this.viewingRooms[index];
      axios.post(`/viewingRooms/updateStatus.json?token=${this.$store.state.token}`, viewingRoom);
    },
  },
  mounted() {
    this.getViewingRooms();
  },
};
</script>
